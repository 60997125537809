import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Mass Transit Component Manufacturing: The Right Manufacturer"
        description="Some mass transit component manufacturers cut corners to save money, leading to subpar products that Don’t meet transit needs. Learn what to look out for here!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <BlogSinglePost
        title="Mass Transit Component Manufacturing: Why It Matters to Choose the Right Manufacturer"
        date="August 20, 2022"
        author="Painted Rhino"
        category=""
        featuredImage={data.featuredImage.childImageSharp.fluid}
        content={
          <>
            <p>
              As the world's population grows and urban areas become more
              congested, the need for efficient{" "}
              <a
                class="inline-link"
                href="https://www.paintedrhino.com/mass-transit-parts-manufacturer/"
              >
                mass transit
              </a>{" "}
              systems becomes more evident. Mass transit components must be able
              to withstand heavy use and harsh conditions, which is why it’s so
              important to choose a reputable and experienced manufacturer.
            </p>

            <p>
              There are many mass transit component manufacturers, but not all
              are created equal. Some manufacturers cut corners to save money,
              which can lead to subpar products that don't meet the needs of
              transit authorities or riders.
            </p>

            <p>
              It's important to research and select a manufacturer with a proven
              track record of producing high-quality components that will last.
            </p>

            <h2>Mass Transit Component Manufacturing Demands Perfection</h2>
            <p>
              When you partner with a reputable mass transit component
              manufacturing company, you can rest assured that your products
              will meet the highest standards for safety and performance. This
              is crucial for ensuring a smooth, efficient, and safe mass transit
              system.
            </p>
            <p>
              If you're looking for a mass transit component manufacturer that
              you can trust, look no further than Painted Rhino. We have over 25
              years of experience in the industry and are committed to producing
              the best components in the business.
            </p>
            <p>
              As your premier parts manufacturer, we will work with you to
              custom-design the perfect solution for your specific needs. To
              achieve our high production standard, we use state-of-the-art
              technology and equipment to produce components built to last. In
              addition, we offer a wide range of services to help you plan,
              design, and implement your mass transit system. Our team of
              experts can provide you with everything you need to get up and
              running quickly and efficiently.
            </p>

            <h2>Superior Manufacturing for a Broad Range of Applications</h2>
            <ul>
              <li>
                <p>
                  <strong>
                    <a
                      class="inline-link"
                      href="https://www.paintedrhino.com/injection-urethane-rotocasting/"
                    >
                      Urethane
                    </a>{" "}
                    Plastic Manufacturing:{" "}
                  </strong>
                  systems becomes more evident. Mass transit components must be
                  able to withstand heavy use and harsh conditions, which is why
                  it’s so important to choose a reputable and experienced
                  manufacturer.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <a
                      class="inline-link"
                      href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/"
                    >
                      Composite Manufacturing
                    </a>{" "}
                    :{" "}
                  </strong>
                  We also specialize in composite manufacturing, which is
                  perfect for producing parts that need to be lightweight and
                  strong. This type of manufacturing is often used for making
                  parts for trains and buses.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <a
                      class="inline-link"
                      href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/"
                    >
                      Vacuum Forming
                    </a>{" "}
                    :{" "}
                  </strong>
                  We also specialize in composite manufacturing, which is
                  perfect for producing parts that need to be lightweight and
                  strong. This type of manufacturing is often used for making
                  parts for trains and buses.
                </p>
              </li>
            </ul>
            <h2>Experience the Painted Rhino Difference</h2>

            <p>
              When you partner with{" "}
              <a href="https://www.paintedrhino.com/about/">Painted Rhino</a>,
              you'll benefit from our experience, expertise, and commitment to
              customer satisfaction. We have a long history of providing
              high-quality transit components to customers worldwide{" "}
            </p>
            <p>
              We're proud to be a leading mass transit parts and systems
              manufacturer.
              <a data-modal-open="modal-contact"> Contact us today</a> to learn
              more about our products and services or to request a quote and
              experience the Painted Rhino Difference yourself.
            </p>
          </>
        }
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }
    ) {
      publicURL
    }
    featuredImage: file(
      relativePath: {
        eq: "blog/Painted_Rhino_Mass_Transit_Component_Manufacturing.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Post;
